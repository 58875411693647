import "../Css/Leaders.css";
import LeadersJson from "../Assets/Data/Leaders.json";
import { FaCircleInfo } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const Leaders = () => {
  const navigate = useNavigate();
  const handleClick = (index) => {
    // navigate("/about/" + name);
    navigate("/about/" + LeadersJson[index].routingName);
  };

  return (
    <div className="Leaders_Overview">
      <div className="row row-cols-1  row-cols-md-1 row-cols-lg-2 g-3 " style={{alignItems:"center",justifyContent:"center"}}>
        {LeadersJson.map((data, index) => (
          <div className="col" key={index}>
            <div
              key={`profile-${index}`}
              onClick={() => handleClick(index)}
              className="custom-card" style={{cursor:"pointer"}}
            >
              <img
                src={data.image}
                className="custom-card-img"
                alt={data.name}
              />
              <div className="custom-card-body">
                <div className="descript_section">
                  <div className="desg">
                    <span className="custom-card-title">{data.name}</span>
                    <span className="custom-card-text1">
                      {data.designation}
                    </span>
                  </div>
                  <span className="custom-card-text">{data.Loc}</span>
                </div>
                <div className="icon_section">
                  <div className="li_icon">
                    <FaCircleInfo
                      className="lin_icon"
                      key={`page-${index}`}
                      onClick={() => handleClick(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="about-bg1"></div>
    </div>
  );
};
export default Leaders;
