// import his1 from "../../../Productpage/Assets/IMG/his-1.svg";
// import med1 from "../../../Productpage/Assets/IMG/medbot-1.svg";
// import chasi1 from "../../../Productpage/Assets/IMG/chasi-1.svg";
// import eth1 from "../../../Productpage/Assets/IMG/ethnikart-1.svg";

export const data = [
  // {
  //   name: "Hospital Information System",
  //   sector: "Healthcare",
  //   link: "his",
  //   images: [
  //     "https://ik.imagekit.io/dzzp3vpnx/Others/his%20mo%202.png?updatedAt=1724220114900",
  //   ],
  //   type: "ipad",
  //   desc: (
  //     <div>
  //       HIS a web based Hospital Management Software that is developed using
  //       latest technologies to manage working of a hospital in a paperless
  //       environment.
  //     </div>
  //   ),
  //   uses: [
  //     {
  //       title: "Industry",
  //       value: "Healthcare",
  //       img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/industry.svg?updatedAt=1712292460144",
  //     },
  //     {
  //       title: "Platform",
  //       value: "Web & Mobile",
  //       img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/box.svg?updatedAt=1712292460115",
  //     },
  //     // {
  //     //   title: "Deliverables",
  //     //   value: "Design, Development, Testing",
  //     //   img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/bulleye.svg?updatedAt=1712292460192",
  //     // },
  //   ],
  // },
  {
    name: "Medbot",
    link: "medbot",
    sector: "Healthcare",
    images: [
      "https://ik.imagekit.io/dzzp3vpnx/Others/medbot?updatedAt=1724220164665",
    ],
    type: "iphone",
    desc: (
      <div>
        Unveiling our cutting-edge healthcare platform powered by OpenAI and
        medLM, providing a seamless handy experience for medical practitioners.
      </div>
    ),
    uses: [
      {
        title: "Industry",
        value: "Healthcare",
        img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/industry.svg?updatedAt=1712292460144",
      },
      {
        title: "Platform",
        value: "Mobile",
        img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/box.svg?updatedAt=1712292460115",
      },
      // {
      //   title: "Deliverables",
      //   value: "AI-chatbot",
      //   img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/bulleye.svg?updatedAt=1712292460192",
      // },
    ],
  },
  {
    name: "Ethnicart",
    sector: "Ecommerce",
    link: "ethnicart",
    images: [
      "https://ik.imagekit.io/dzzp3vpnx/Others/ethnickart%203.png?updatedAt=1724220115130",
    ],
    type: "laptop",
    desc: (
      <div>
        Ethnickart is your one-stop shop for ethnic fashion in India. We offer a
        wide range of trendy clothing products, all conveniently available
        online.
      </div>
    ),
    uses: [
      {
        title: "Industry",
        value: "Ecommerce",
        img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/industry.svg?updatedAt=1712292460144",
      },
      {
        title: "Platform",
        value: "Web & Mobile",
        img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/box.svg?updatedAt=1712292460115",
      },
      // {
      //   title: "Deliverables",
      //   value: "Online Shopping",
      //   img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/bulleye.svg?updatedAt=1712292460192",
      // },
    ],
  },
  {
    name: "Chasi",
    sector: "Agrotech",
    link: "chasi",
    images: [
      "https://ik.imagekit.io/dzzp3vpnx/Others/chasi%203.png?updatedAt=1724220114985",
    ],
    type: "iphone",
    desc: (
      <div>
        With Chasi, you'll have everything you need at your fingertips, from
        real-time market prices to the latest government schemes.
      </div>
    ),
    uses: [
      {
        title: "Industry",
        value: "Agrotech",
        img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/industry.svg?updatedAt=1712292460144",
      },
      {
        title: "Platform",
        value: "Mobile",
        img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/box.svg?updatedAt=1712292460115",
      },
      // {
      //   title: "Deliverables",
      //   value: "Tracking, News",
      //   img: "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/bulleye.svg?updatedAt=1712292460192",
      // },
    ],
  },
];
